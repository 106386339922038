import React from 'react';
import { GovlyTableProvider, GovlyTableProviderProps } from 'app/molecules/GovlyTable/GovlyTableContext';
import { useGovlyTable, UseGovlyTableArgs } from 'app/molecules/GovlyTable/useGovlyTable';
import { GovlyTablePagination } from 'app/molecules/GovlyTable/GovlyTablePagination';
import { GovlyTableEmptyState } from 'app/molecules/GovlyTable/GovlyTableEmptyState';
import { NonIdealStateProps } from '@blueprintjs/core';
import { ColumnDef } from '@tanstack/react-table';
import { Loading } from 'app/atoms/Loading/Loading';
import { GovlyTableCard, GovlyTableCardProps } from './GovlyTableCard';

/** Hoist commonly used props for better DX */
type HoistedTableProps = 'columns' | 'data' | 'initialState' | 'getRowId';

type TableProps<Data> = Pick<UseGovlyTableArgs<Data>, HoistedTableProps> & {
  tableProps?: Omit<UseGovlyTableArgs<Data>, HoistedTableProps>;
};

type CommonProps = {
  title?: string | React.ReactElement;
  emptyStateProps?: NonIdealStateProps;
  loadingRowCount?: number;
  striped?: boolean;
};

type Props<Data> = React.PropsWithChildren<
  TableProps<Data> & GovlyTableCardProps & CommonProps & Omit<GovlyTableProviderProps<Data>, 'table'>
>;

type RootProps<Data> = Omit<Props<Data>, 'title' | 'emptyStateProps' | 'bodyProps' | 'cardProps'>;

export type { Props as GovlyTableProps, RootProps as GovlyTableRootProps };

export function GovlyTableRoot<Data>({
  tableProps,
  columns,
  data,
  initialState,
  getRowId,
  children,
  isLoading = false,
  loadingRowCount = 10,
  ...context
}: RootProps<Data>) {
  const { table } = useGovlyTable({
    initialState,
    getRowId,
    enableHiding: false,
    ...withLoading({ columns, data, isLoading, loadingRowCount }),
    ...tableProps
  });

  return (
    <GovlyTableProvider table={table} isLoading={isLoading} {...context}>
      {children}
    </GovlyTableProvider>
  );
}

export function GovlyTable<Data>({
  bodyProps,
  cardProps,
  striped,
  children,
  emptyStateProps,
  title,
  ...rootProps
}: Props<Data>) {
  return (
    <GovlyTableRoot {...rootProps}>
      <GovlyTableCard striped={striped} bodyProps={bodyProps} cardProps={{ title, ...cardProps }}>
        {children}
        <GovlyTablePagination />
        <GovlyTableEmptyState {...emptyStateProps} />
      </GovlyTableCard>
    </GovlyTableRoot>
  );
}

function withLoading<Data>({
  columns,
  data,
  isLoading,
  loadingRowCount
}: {
  columns: ColumnDef<Data>[];
  data: Data[];
  isLoading: boolean;
  loadingRowCount: number;
}) {
  return isLoading && !data.length
    ? {
        columns: columns.map((c): typeof c => ({ ...c, cell: () => <Loading type="text" /> })),
        data: Array.from({ length: loadingRowCount }, (_, i) => ({ id: i })) as Data[]
      }
    : { columns, data };
}
