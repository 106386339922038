import React, { useState, useRef, useEffect } from 'react';
import { Button, RadioGroup, Radio, Alert } from '@blueprintjs/core';
import { Formik, Form } from 'formik';
import { Editor } from 'app/molecules/Editor/Editor';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { useGetOppWorkspaceQuery } from 'api/oppWorkspacesApi';
import { formErrorToast, successToast } from 'app/lib/toaster';
import { FollowerSelectInput } from 'app/organisms/FollowerSelectInput/FollowerSelectInput';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { CommentShow, FollowDisplayable } from 'types/__generated__/GovlyApi';
import { FileUploader } from 'app/molecules/FileUploaders/FileUploader/FileUploader';
import { AttachmentEntityTitle } from 'app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';

type FormValues = {
  id: string;
  content: string;
  organizationUserId: string;
  commentableType: string;
  oppId: string;
  commentableId: string;
  follows: Pick<
    FollowDisplayable,
    'organizationUserId' | 'notifications' | 'state' | 'createdById' | 'organizationUser'
  >[];
  notifyIds: string[];
  attachments: { name: string; signedId: string }[];
};

const NEW_COMMENT_ID = 'new-comment';

type OppWorkspaceCommentFormProps = {
  onSubmit: (
    values: Omit<FormValues, 'follows' | 'attachments'> & { attachmentSignedIds: string[]; notifyIds: string[] }
  ) => { unwrap: () => Promise<unknown> };
  oppId: string;
  workspaceId: string;
  comment?: CommentShow;
  afterSubmit?: () => void;
  isLoading: boolean;
};

export const OppWorkspaceCommentForm = ({
  comment,
  workspaceId,
  oppId,
  onSubmit,
  afterSubmit = () => {},
  isLoading
}: OppWorkspaceCommentFormProps) => {
  const workspaceImprovementsEnabled = useFeatureFlag('workspace-improvements');

  const [formState, setFormState] = useState<'idle' | 'uploading' | 'confirming'>('idle');
  const [mentions, setMentions] = useState<string[]>([]);
  const [notifyGroup, setNotifyGroup] = useState('followers');

  useEffect(() => {
    if (mentions.length > 0) setNotifyGroup('select');
  }, [mentions, setNotifyGroup]);

  const editorRef = useRef<HTMLTextAreaElement>();

  const currentUserId = useCurrentUserAttribute('id');
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: { follows = [], attachments = [] } = {}, isLoading: workspaceLoading } = useGetOppWorkspaceQuery({
    id: workspaceId
  });

  const commentAttachments = attachments
    .filter(attachment => (attachment.commentId ? attachment.commentId === comment?.id : false))
    .map(({ attachment }) => attachment)
    .filter(attachment => attachment !== undefined)
    .map(attachment => ({ signedId: attachment.signedId ?? '', name: attachment.name ?? '' }));

  if (workspaceLoading) {
    return null;
  }

  const notifiableUsers = follows
    .filter(f => f.organizationUserId !== currentUserId)
    .map(({ organizationUser }) => organizationUser)
    .filter(user => user !== undefined);

  return (
    <Formik<FormValues>
      enableReinitialize
      onSubmit={async (values, { resetForm }) => {
        try {
          const { follows = [], id = '', attachments, ...rest } = values;
          const signedIds = attachments.map(({ signedId }) => signedId);

          let notifyIds: string[] = [];

          if (notifyGroup === 'followers') {
            notifyIds = notifiableUsers.map(({ id }) => id);
          }

          if (notifyGroup === 'select') {
            notifyIds = follows.map(({ organizationUserId }) => organizationUserId);
          }

          await onSubmit({ ...rest, id, notifyIds, attachmentSignedIds: signedIds }).unwrap();
          setNotifyGroup('followers');

          window.sessionStorage.removeItem(`commentDraft-${workspaceId}`);
          if (values.id !== NEW_COMMENT_ID) {
            successToast('Message updated.');
          } else {
            resetForm();
            if (editorRef.current) {
              editorRef.current.value = '';
            }
            successToast('Message posted.');
          }

          setMentions([]);
          afterSubmit();
        } catch (error) {
          formErrorToast(error as number);
        }
      }}
      initialValues={{
        id: comment?.id ?? NEW_COMMENT_ID,
        content:
          comment?.contentRaw || JSON.parse(window.sessionStorage.getItem(`commentDraft-${workspaceId}`) || '""') || '',
        organizationUserId: currentUserId,
        commentableType: 'Workspace',
        oppId,
        commentableId: workspaceId,
        follows: notifiableUsers
          .filter(({ email }) => mentions.includes(email))
          .map(organizationUser => ({
            organizationUserId: organizationUser.id,
            notifications: 'user_setting',
            state: 'following',
            createdById: currentUserId,
            organizationUser
          })),
        notifyIds: [],
        attachments: commentAttachments
      }}
    >
      {({ values, setFieldValue, isSubmitting, submitForm }) => (
        <Form>
          <div className="flex flex-col gap-y-4">
            <Editor
              // @ts-expect-error editor not typed
              id={comment?.id ?? NEW_COMMENT_ID}
              // @ts-expect-error editor not typed
              onChange={({ target: { value: newValue } }) => {
                setFieldValue('content', newValue);
                if (!comment?.id) {
                  window.sessionStorage.setItem(`commentDraft-${workspaceId}`, JSON.stringify(newValue));
                }
              }}
              inputProps={{ value: values.content }}
              ref={editorRef}
              notifiableUsers={notifiableUsers}
              setMentions={setMentions}
              trixEditorProps={{ placeholder: 'Write a new message...' }}
              hideUploads={workspaceImprovementsEnabled}
              formGroupProps={{
                className: 'mb-0',
                contentClassName: 'mt-0',
                labelFor: 'comment'
              }}
            />

            <div className="space-y-2">
              <div className="flex justify-between flex-row-reverse">
                <Button
                  intent="primary"
                  loading={isSubmitting || isLoading}
                  disabled={formState === 'uploading' || !(values.attachments.length > 0 || values.content.length > 0)}
                  onClick={() => {
                    if (notifiableUsers.length > 0) {
                      setFormState('confirming');
                    } else {
                      submitForm();
                    }
                  }}
                >
                  Submit
                </Button>

                {currentUser?.compliancePreventUploads || !workspaceImprovementsEnabled ? null : (
                  <FileUploader
                    id={comment?.id || NEW_COMMENT_ID}
                    multiple
                    onInitialize={() => setFormState('uploading')}
                    onAttach={async attachment => {
                      setFormState('idle');
                      setFieldValue('attachments', [...values.attachments, attachment]);
                    }}
                  >
                    <Button outlined icon="paperclip">
                      Add Attachments
                    </Button>
                  </FileUploader>
                )}
              </div>

              {workspaceImprovementsEnabled ? (
                <div className="flex flex-wrap gap-2">
                  {values.attachments.map((attachment, index) => (
                    <div key={index} className="flex items-center gap-x-2">
                      <AttachmentEntityTitle
                        {...{
                          extension: attachment.name.split('.').pop() ?? '',
                          url: '',
                          filename: attachment.name
                        }}
                        onDelete={() => {
                          setFieldValue(
                            'attachments',
                            values.attachments.filter((_, i) => i !== index)
                          );
                        }}
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <Alert
            isOpen={formState === 'confirming'}
            confirmButtonText="Submit"
            cancelButtonText="Cancel"
            intent="primary"
            onClose={() => setFormState('idle')}
            onConfirm={() => {
              submitForm();
            }}
            loading={isSubmitting}
          >
            {notifiableUsers.length > 0 && (
              <div className="flex flex-col">
                <p className="mb-2 text-base font-medium">When I post this, notify...</p>
                <RadioGroup selectedValue={notifyGroup} onChange={e => setNotifyGroup(e.currentTarget.value)}>
                  <Radio label="Everyone in the workspace" value="followers" />
                  <Radio label="No one" value="nobody" />
                  <Radio label="Only the people I select..." value="select" />
                </RadioGroup>
                {notifyGroup === 'select' && (
                  // @ts-expect-error follower select input not typed
                  <FollowerSelectInput
                    hideFollowSettings
                    name="follows"
                    defaultButtonText="Select recipients"
                    organizationUsers={notifiableUsers}
                  />
                )}
              </div>
            )}
          </Alert>
        </Form>
      )}
    </Formik>
  );
};
