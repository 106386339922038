import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Tag, Dialog } from '@blueprintjs/core';
import { createColumnHelper, PaginationState } from '@tanstack/react-table';

import { useSearchCatalogQuery } from 'api/souptoolsCatalogsApi';
import { ControlGroup } from 'app/molecules/ControlGroup/ControlGroup';
import { validations } from 'app/organisms/SouptoolsTrClin/SouptoolsTrClin';
import { SouptoolsClinForm } from 'app/organisms/SouptoolsClinForm/SouptoolsClinForm';
import { asCurrency } from 'app/lib/numbers';
import { SouptoolsClin } from 'types/__generated__/GovlyApi';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTableToolbar } from 'app/molecules/GovlyTable/GovlyTableToolbar';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

const columnHelper = createColumnHelper<SouptoolsClin>();

export const SouptoolsCatalogPage = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: 50, // this is hardcoded in the backend
    pageIndex: 0
  });
  const [clin, setClin] = useState<SouptoolsClin | undefined>(undefined);
  const [searchParams, setSearchParams] = useSearchParams();

  const query = {
    column: searchParams.get('column') || 'partnumber',
    query: searchParams.get('query') || ''
  };

  const { data, isFetching, isLoading } = useSearchCatalogQuery({
    page: pagination.pageIndex + 1,
    ...query
  });
  const { clins = [], meta } = data ?? {};

  const columns = useMemo(
    () => [
      columnHelper.accessor('clin', {
        header: 'Clin / Part Number',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e => (
          <div>
            <span>
              Clin:{' '}
              <Tag intent="primary" minimal interactive onClick={() => setClin(e.row.original)}>
                {e.row.original.clin}
              </Tag>
            </span>
            <p className="mb-0">Part Number: {e.row.original.partnumber}</p>
          </div>
        )
      }),
      columnHelper.accessor('provider', {
        header: 'Provider',
        enableSorting: false,
        enableColumnFilter: false
      }),
      columnHelper.accessor('sspart', {
        header: 'Sspart',
        enableSorting: false,
        enableColumnFilter: false
      }),
      columnHelper.accessor('sewpprice', {
        header: 'Sewp Price',
        enableSorting: false,
        enableColumnFilter: false,
        cell: e => asCurrency(e.row.original.sewpprice)
      })
    ],
    []
  );

  const tableData = useMemo(() => clins, [clins]);

  const ToolbarComponents = () => {
    return (
      <Formik
        initialValues={query}
        onSubmit={async ({ column, query }) => {
          if (!query) return setSearchParams({});
          setSearchParams({ column, query });
        }}
      >
        {() => (
          <Form>
            <ControlGroup
              filterName="column"
              filterOptions={[
                { value: 'partnumber', label: 'Part Number' },
                { value: 'clin', label: 'Clin' },
                { value: 'provider', label: 'Provider' },
                { value: 'sspart', label: 'Sspart' }
              ]}
              inputName="query"
              onClear={() => setSearchParams({})}
            />
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GovlyTable
        trackingObject="souptools_catalog_table"
        striped={true}
        columns={columns}
        data={tableData}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>Catalog Clins</CardHeading>
            <GovlyTableRowCountTag />
          </span>
        }
        cardProps={{
          rightElement: (
            <GovlyTableToolbar>
              <ToolbarComponents />
            </GovlyTableToolbar>
          )
        }}
        isLoading={isLoading || isFetching}
        emptyStateProps={{
          icon: 'warning-sign',
          title: 'No Catalog Clins Found',
          description: 'Try Changing your search query.'
        }}
        tableProps={{
          manualPagination: true,
          onPaginationChange: setPagination,
          rowCount: meta?.total ?? 0,
          state: { pagination }
        }}
        initialState={{
          pagination
        }}
      />

      <Dialog isOpen={!!clin} onClose={() => setClin(undefined)} className="min-w-[50%] p-0">
        {!!clin && (
          <SouptoolsClinForm clin={clin} validations={validations} onClose={() => setClin(undefined)} readOnly />
        )}
      </Dialog>
    </>
  );
};
