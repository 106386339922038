import React from 'react';
import { WorkspaceShow } from 'types/__generated__/GovlyApi';
import { Tag, Tooltip } from '@blueprintjs/core';
import { AttachmentEntityTitle } from 'app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { AttachmentEntityTitleUtils } from 'app/organisms/OppWorkspaceAttachmentsCard/utils';

export const OppWorkspaceAttachmentsCard = ({ workspace }: { workspace: WorkspaceShow }) => {
  const { attachments = [] } = workspace;

  const workspaceAttachments = attachments.slice().sort((a, b) => b.createdAt?.localeCompare(a.createdAt ?? '') ?? 0);

  return (
    <dl className="grid grid-cols-2 gap-y-4">
      <div className="flex flex-col gap-y-1 col-span-2">
        {workspaceAttachments.map(attachment => (
          <Tooltip key={attachment.id} content={attachment.attachment?.name} hoverOpenDelay={1000}>
            <AttachmentEntityTitle
              tooltipProps={{ className: 'w-full' }}
              tags={
                !attachment.commentId ? (
                  <Tag intent="warning" minimal round>
                    Comment deleted
                  </Tag>
                ) : null
              }
              {...AttachmentEntityTitleUtils.fromWorkspaceAttachment(attachment)}
            />
          </Tooltip>
        ))}
      </div>
    </dl>
  );
};
