import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';
import React from 'react';

export const EditorToolbar = () => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const uploadsPrevented = currentUser?.compliancePreventUploads;
  const workspaceImprovementsEnabled = useFeatureFlag('workspace-improvements');

  return (
    <div className="trix-button-row w-full flex flex-row-reverse">
      <span className="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
        <button
          className="trix-button trix-button--icon trix-button--icon-bold"
          data-trix-attribute="bold"
          data-trix-key="b"
          title="Bold"
          tabIndex={-1}
        >
          Bold
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-italic"
          data-trix-attribute="italic"
          data-trix-key="i"
          title="Italic"
          tabIndex={-1}
        >
          Italic
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-strike"
          data-trix-attribute="strike"
          title="Strikethrough"
          tabIndex={-1}
        >
          Strikethrough
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-link"
          data-trix-attribute="href"
          data-trix-action="link"
          data-trix-key="k"
          title="Link"
          tabIndex={-1}
        >
          Link
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-heading-1"
          data-trix-attribute="heading1"
          title="Title"
          tabIndex={-1}
        >
          Title
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-quote"
          data-trix-attribute="quote"
          title="Quote"
          tabIndex={-1}
        >
          Quote
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-bullet-list"
          data-trix-attribute="bullet"
          title="Bullets"
          tabIndex={-1}
        >
          Bullets
        </button>
        <button
          type="button"
          className="trix-button trix-button--icon trix-button--icon-number-list"
          data-trix-attribute="number"
          title="Numbers"
          tabIndex={-1}
        >
          Numbers
        </button>

        {uploadsPrevented || workspaceImprovementsEnabled ? null : (
          <button
            type="button"
            className="trix-button trix-button--icon trix-button--icon-attach"
            data-trix-action="attachFiles"
            title="Attach Files"
            tabIndex={-1}
          >
            Attach Files
          </button>
        )}
      </span>
    </div>
  );
};
