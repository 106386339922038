import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Main } from 'app/atoms/Main/Main';
import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { Loading } from 'app/atoms/Loading/Loading';
import { useWorkspaceFromSearchDecorator } from 'api/oppsApi';
import { useGetOppWorkspaceQuery, useAddWorkspaceInteractionMutation } from 'api/oppWorkspacesApi';
import { OppWorkspaceTimeline } from 'app/organisms/OppWorkspaceTimeline/OppWorkspaceTimeline';
import { OppWorkspaceCard } from 'app/organisms/OppWorkspaceCard/OppWorkspaceCard';
import { OppWorkspaceOppDetails } from 'app/organisms/OppWorkspaceOppDetails/OppWorkspaceOppDetails';
import { AppLoading } from 'app/atoms/AppLoading/AppLoading';
import { OppWorkspaceWorkflowStage } from 'app/organisms/OppWorkspaceWorkflowStage/OppWorkspaceWorkflowStage';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { OppWorkspaceCommentCreateForm } from 'app/organisms/OppWorkspaceCommentCreateForm/OppWorkspaceCommentCreateForm';
import { OppWorkspaceQuoteForm } from 'app/organisms/OppWorkspaceQuoteForm/OppWorkspaceQuoteForm';
import { Select } from '@blueprintjs/select';
import { Button, MenuItem } from '@blueprintjs/core';
import { useGetCurrentUserQuery } from 'api/currentUserApi';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';

const TABS = [
  {
    label: 'Workspace',
    value: 'Workspace'
  },
  {
    label: 'Opportunity',
    value: 'Opportunity'
  }
];

export const OppWorkspacePageContents = ({
  id,
  inDrawer = false,
  breadcrumbs,
  tab
}: {
  id: string;
  inDrawer?: boolean;
  breadcrumbs?: React.ReactNode;
  tab?: string;
}) => {
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');

  const [tabState, setCurrentTab] = useState(tab ?? 'Workspace');
  const currentTab = tab ?? tabState;

  const {
    data: fetchedWorkspace,
    isLoading: workspaceLoading,
    isSuccess: workspaceLoaded,
    isError
  } = useGetOppWorkspaceQuery({ id });
  const { data: currentUser } = useGetCurrentUserQuery();

  const workspaceFromSearch = useWorkspaceFromSearchDecorator({ id });
  const workspace = { ...workspaceFromSearch, ...fetchedWorkspace };
  const oppId = workspace?.workableId;

  const [addWorkspaceInteraction] = useAddWorkspaceInteractionMutation();

  useEffect(() => {
    addWorkspaceInteraction({ id });
  }, [addWorkspaceInteraction, id]);

  if (isEmpty(workspace) && workspaceLoading) {
    return <AppLoading />;
  }

  if (isError) {
    return <NotFound />;
  }

  let pane = <Loading />;

  if (workspaceLoaded) {
    pane = (
      <div className="space-y-4">
        {currentUser?.compliancePreventUploads ? null : (
          <OppWorkspaceQuoteForm
            quotes={workspace.quotes}
            workspaceId={workspace.id ?? ''}
            workspaceName={workspace.name ?? ''}
            workableDisplayName={workspace.workableDisplayName ?? ''}
            workableId={workspace.workableId ?? ''}
          />
        )}
        <OppWorkspaceCommentCreateForm oppId={oppId ?? ''} workspaceId={workspace.id ?? ''} />
        <OppWorkspaceTimeline oppId={oppId} workspaceId={workspace.id} />
      </div>
    );
  }

  if (workspaceLoaded && currentTab === 'Opportunity') {
    pane = <OppWorkspaceOppDetails workspaceIdProp={workspace.id} oppIdProp={oppId} />;
  }

  const hideClass = inDrawer ? 'xl' : 'lg';

  return (
    <Main className="min-h-screen max-w-full bg-gray-50 py-5">
      {oppShowNavEnabled ? null : (
        <MainColumn className="space-y-2">
          {breadcrumbs && <div className="lg:order-first lg:flex lg:justify-between lg:space-y-0">{breadcrumbs}</div>}

          <div className="inline-block">
            <Select
              items={TABS}
              onItemSelect={i => setCurrentTab(i.value)}
              itemRenderer={(item, opts) => (
                <MenuItem
                  key={item.label}
                  text={item.label}
                  active={item.value === currentTab}
                  onClick={opts.handleClick}
                />
              )}
              popoverProps={{
                minimal: true,
                modifiers: {
                  offset: { enabled: true }
                }
              }}
            >
              <Button outlined rightIcon="caret-down" className="w-32 justify-between">
                {currentTab}
              </Button>
            </Select>
          </div>
        </MainColumn>
      )}

      <MainColumn columnSpan={8}>
        <div>
          <OppWorkspaceWorkflowStage
            workspaceId={workspace.id}
            workflowStage={workspace.workflowStage}
            className={`hidden ${hideClass}:block mb-6`}
          />
          {pane}
        </div>
      </MainColumn>
      {fetchedWorkspace && (
        <MainColumn columnSpan={4}>
          <OppWorkspaceCard workspace={fetchedWorkspace} />
        </MainColumn>
      )}
    </Main>
  );
};
