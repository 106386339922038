import React from 'react';
import {
  Button,
  EntityTitle,
  EntityTitleProps,
  Icon,
  IconProps,
  IconSize,
  Tooltip,
  TooltipProps
} from '@blueprintjs/core';
import { match } from 'ts-pattern';
import { cn } from 'app/lib/cn';

export const AttachmentEntityTitle = ({
  filename,
  url,
  extension,
  onDelete,
  onPreview,
  tooltipProps: { className: tooltipClassName, ...tooltipProps } = {},
  ...rest
}: {
  filename: string;
  url: string;
  extension: string;
  onPreview?: () => void;
  onDelete?: () => void;
  tooltipProps?: Partial<TooltipProps>;
} & Partial<EntityTitleProps>) => {
  return (
    <Tooltip
      content={filename}
      hoverOpenDelay={1000}
      className={cn('max-w-full w-[320px]', tooltipClassName)}
      {...tooltipProps}
    >
      <div className="border border-gray-100 py-1 px-2 group flex items-center outline-none h-[34px]">
        <div className="flex-grow min-w-0">
          <EntityTitle
            titleURL={url}
            ellipsize
            title={filename}
            icon={
              <Icon
                size={IconSize.LARGE}
                {...match<string | undefined, IconProps>(extension)
                  .with('pdf', () => ({ icon: 'document', intent: 'danger' }))
                  .with('doc', 'docx', () => ({ icon: 'paragraph', intent: 'primary' }))
                  .with('csv', 'xlsx', () => ({ icon: 'th', intent: 'success' }))
                  .with('mov', 'mp4', () => ({ icon: 'video', intent: 'warning' }))
                  .with('png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp', () => ({
                    icon: 'media',
                    intent: 'warning'
                  }))
                  .otherwise(() => ({ icon: 'document' }))}
              />
            }
            {...rest}
          />
        </div>

        {onDelete || onPreview ? (
          <div className="hidden group-hover:flex group-focus:flex flex-shrink-0 ml-2">
            {onDelete ? (
              <Button
                aria-label="Remove attachment"
                icon="small-cross"
                minimal
                intent="danger"
                small
                onClick={onDelete}
              />
            ) : null}
            {onPreview ? <Button aria-label="Preview" icon="eye-open" minimal small onClick={onPreview} /> : null}
          </div>
        ) : null}
      </div>
    </Tooltip>
  );
};
