import React from 'react';
import { useParams } from 'react-router-dom';

import { MainColumn } from 'app/atoms/MainColumn/MainColumn';
import { ContactsCard } from 'app/molecules/ContactsCard/ContactsCard';
import { AddressesCard } from 'app/molecules/AddressesCard/AddressesCard';
import { OppDetails } from 'app/organisms/OppDetails/OppDetails';
import { OppDetailsActions } from 'app/organisms/OppDetailsActions/OppDetailsActions';
import { OppDetailsProviders } from 'app/organisms/OppDetailsProviders/OppDetailsProviders';
import { OppDetailsProviderContacts } from 'app/organisms/OppDetailsProviderContacts/OppDetailsProviderContacts';
import { OppWorkspaces } from 'app/organisms/OppWorkspaces/OppWorkspaces';
import { Breadcrumbs } from 'app/molecules/Breadcrumbs/Breadcrumbs';
import { useGetOppQuery } from 'api/oppsApi';
import { QuestionsAndAnswers } from 'app/molecules/QuestionsAndAnswers/QuestionsAndAnswers';
import { NavSelect } from 'app/molecules/NavSelect/NavSelect';
import { NotFound } from 'app/molecules/NotFound/NotFound';
import { useFeatureFlag } from 'app/hooks/useFeatureFlag';

export const OppDetailsOverviewPage = () => {
  const { id } = useParams();
  const { data } = useGetOppQuery({ id: id ?? '' }, { skip: !id });
  const oppShowNavEnabled = useFeatureFlag('opp-show-nav');

  if (!id) return <NotFound />;

  const { displayName, latestData, contacts = [], recordLocations = [], addresses = [] } = data || {};
  const locations = recordLocations.length > 0 ? recordLocations : addresses;
  const questions = latestData?.questions || [];

  return (
    <>
      {oppShowNavEnabled ? null : (
        <MainColumn className="space-y-2">
          <Breadcrumbs items={[{ text: 'Opportunities', to: '/opportunities' }, { text: displayName }]} />
          <div className="inline-block">
            <NavSelect
              items={[
                { label: 'Details', value: `opportunities/${id}` },
                { label: 'Workspaces', value: `opportunities/${id}/workspaces` }
              ]}
            />
          </div>
        </MainColumn>
      )}

      <MainColumn className="lg:hidden" data-test="opp-details-mobile">
        <OppDetailsActions id={id} />
        <OppDetails id={id} />
        {oppShowNavEnabled ? null : <OppWorkspaces id={id} />}
        <OppDetailsProviderContacts id={id} className="lg:hidden" />
        <ContactsCard contacts={contacts} />
        <AddressesCard addresses={locations} />
      </MainColumn>

      <MainColumn columnSpan={8} className="hidden lg:block" data-test="opp-details-left-col">
        <OppDetails id={id} />
        <ContactsCard contacts={contacts} />
        <QuestionsAndAnswers questions={questions} />
        <AddressesCard addresses={locations} />
      </MainColumn>
      <MainColumn columnSpan={4} className="hidden lg:block" data-test="opp-details-right-col">
        <OppDetailsActions id={id} />
        {oppShowNavEnabled ? null : <OppWorkspaces id={id} />}
        <OppDetailsProviderContacts id={id} />
        <OppDetailsProviders id={id} />
      </MainColumn>
    </>
  );
};
