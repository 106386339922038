import React from 'react';
import { Button, ButtonProps, HTMLTable, InputGroup } from '@blueprintjs/core';
import { flexRender } from '@tanstack/react-table';
import { useGovlyTableContext } from 'app/molecules/GovlyTable/GovlyTableContext';
import { match } from 'ts-pattern';
import { useEventTracking } from 'app/hooks/useEventTracking';
import { cn } from 'app/lib/cn';
import { useGetColumnWidth } from './useGetColumnWidth';

const BUTTON_PROPS: ButtonProps = {
  className: '-ml-[7px] -mt-[5px] text-xs font-medium uppercase text-gray-500',
  small: true,
  minimal: true
};

export function GovlyTableBase<Data>() {
  const { table, isLoading, trackingObject, isFixedLayout, striped } = useGovlyTableContext<Data>();
  const { trackEvent } = useEventTracking();
  const getColumnWidth = useGetColumnWidth();

  return (
    <HTMLTable className={cn('w-full', { 'table-fixed': isFixedLayout })}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const headerText = header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext());

              return (
                <th
                  key={header.id}
                  style={{ width: getColumnWidth(header.column.getSize()) }}
                  className="p-3 text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-50 first:pl-5 last:pr-5"
                >
                  <span className="space-y-2">
                    {header.column.getCanSort() ? (
                      <Button
                        {...BUTTON_PROPS}
                        disabled={isLoading}
                        onClick={() => {
                          header.column.toggleSorting();
                          trackEvent({
                            object: trackingObject,
                            action: 'toggle_sort',
                            properties: { column: header.column.columnDef.header }
                          });
                        }}
                        rightIcon={match(header.column.getIsSorted())
                          .with('asc', () => 'chevron-up' as const)
                          .with('desc', () => 'chevron-down' as const)
                          .otherwise(() => undefined)}
                        aria-label={match(header.column.getIsSorted())
                          .with('asc', () => `${headerText} ascending`)
                          .with('desc', () => `${headerText} descending`)
                          .otherwise(() => undefined)}
                        text={headerText}
                      />
                    ) : (
                      <span>{headerText}</span>
                    )}

                    {header.column.getCanFilter() ? (
                      <InputGroup
                        className="max-w-sm"
                        value={(header.column.getFilterValue() ?? '') as string}
                        small
                        disabled={isLoading}
                        placeholder={`Filter by ${header.column.columnDef.header}`}
                        onChange={e => {
                          header.column.setFilterValue(e.target.value || undefined);
                          trackEvent({
                            object: trackingObject,
                            action: 'filter',
                            properties: { column: header.column.columnDef.header }
                          });
                        }}
                      />
                    ) : null}
                  </span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>

      <tbody className="divide-y-gray-200 dark:divide-y-gray-800 divide-y">
        {table.getRowModel().rows.map(row => (
          <tr
            key={row.id}
            className={cn('border-b border-gray-200 dark:border-gray-800', { 'even:bg-gray-50 odd:bg-white': striped })}
          >
            {row.getVisibleCells().map(cell => (
              <td key={cell.id} className="align-middle p-3 first:pl-5 last:pr-5">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  );
}
