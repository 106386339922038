import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';

import { OrganizationUserDisplayable } from 'types/__generated__/GovlyApi';
import { useGetOppQuery } from 'api/oppsApi';
import { useGetCurrentUserQuery } from 'api/currentUserApi';

import { AvatarWithName } from 'app/molecules/AvatarWithName/AvatarWithName';
import { OppDetailsCreateWorkspace } from 'app/organisms/OppDetailsCreateWorkspace/OppDetailsCreateWorkspace';

import { CardHeading } from 'app/atoms/Typography/Typography';
import { GovlyTable } from 'app/molecules/GovlyTable/GovlyTable';
import { GovlyTableRowCountTag } from 'app/molecules/GovlyTable/GovlyTableRowCountTag';

type OppDetailsProviderContactsProps = {
  id: string;
} & Partial<React.HTMLAttributes<HTMLDivElement>>;

const columnHelper = createColumnHelper<OrganizationUserDisplayable>();

export const OppDetailsProviderContacts = ({ id, ...rest }: OppDetailsProviderContactsProps) => {
  const { data: currentUser, isLoading: currentUserLoading } = useGetCurrentUserQuery();
  const { data, isLoading } = useGetOppQuery({
    id
  });

  const { providerPartnerships = [], contractVehicle } = data ?? {};
  const isPublic = contractVehicle?.public;

  const contacts = useMemo(() => {
    return providerPartnerships
      ?.map(({ representatives }) => representatives)
      .flat()
      ?.map(partnershipContact => partnershipContact?.organizationUser)
      .filter(Boolean) as OrganizationUserDisplayable[];
  }, [providerPartnerships]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: 'Name',
        sortingFn: 'basic',
        filterFn: (row, id, filterValue) => {
          const rowValue = row.getValue(id);

          if (!rowValue) {
            return true;
          }

          return (
            !!row.original.name?.toLowerCase()?.includes(filterValue) ||
            !!row.original.organizationName?.toLowerCase()?.includes(filterValue)
          );
        },
        cell: e => (
          <div className="flex flex-wrap items-center justify-between">
            <div className="mr-2">
              <AvatarWithName
                initials={e.row.original.initials}
                imgSrc={e.row.original.avatar?.thumbUrl}
                avatarColor={e.row.original.avatarColor}
                id={e.row.original.id}
                email={e.row.original.email}
                name={e.row.original.name}
              />
            </div>
            <div className="mt-2">
              <OppDetailsCreateWorkspace
                name={`${currentUser?.organization?.name} <> ${e.row.original.name}`}
                oppId={id}
                follows={[
                  {
                    organizationUserId: e.row.original.id,
                    state: 'following',
                    organizationUser: { organizationId: e.row.original.organizationId }
                  },
                  {
                    organizationUserId: currentUser?.id,
                    state: 'following',
                    notifications: 'user_setting',
                    organizationUser: { organizationId: currentUser?.organization?.id }
                  }
                ]}
                tooltipProps={{ content: `Create a Workspace with ${e.row.original.name}` }}
              />
            </div>
          </div>
        )
      })
    ],
    [currentUser?.organization?.name, currentUser?.organization?.id, currentUser?.id, id]
  );

  if (isPublic || isLoading || currentUserLoading || !providerPartnerships?.length || !contacts.length) {
    return null;
  }

  return (
    <div {...rest}>
      <GovlyTable
        trackingObject="provider_contacts_table"
        columns={columns}
        data={contacts}
        title={
          <span className="flex gap-2 items-center">
            <CardHeading>Users</CardHeading>
            <GovlyTableRowCountTag />
            <span className="text-sm text-gray-500 font-normal">
              Provider contacts are available for collaboration in Workspaces.
            </span>
          </span>
        }
        initialState={{
          sorting: [{ id: 'name', desc: false }],
          pagination: {
            pageSize: 3
          }
        }}
      />
    </div>
  );
};
