import React from 'react';
import { Comment } from 'app/molecules/Comment/Comment';
import { useCurrentUserAttribute } from 'api/currentUserApi';
import { deleteToast } from 'app/lib/toaster';
import {
  useUpdateOppWorkspaceCommentMutation,
  useDeleteOppWorkspaceCommentMutation
} from 'api/oppWorkspaceCommentsApi';
import { CommentShow, WorkspaceAttachmentShow } from 'types/__generated__/GovlyApi';
import { ErrorBoundary } from 'app/atoms/ErrorBoundary/ErrorBoundary';
import { CardError } from 'app/atoms/ErrorFallback/CardError';
import { AttachmentEntityTitle } from 'app/organisms/OppWorkspaceAttachmentsCard/AttachmentEntityTitle';
import { AttachmentEntityTitleUtils } from 'app/organisms/OppWorkspaceAttachmentsCard/utils';
import { OppWorkspaceCommentForm } from './OppWorkspaceCommentForm';

export const OppWorkspaceComment = ({
  comment,
  oppId,
  attachments
}: {
  comment: CommentShow;
  oppId: string;
  attachments: WorkspaceAttachmentShow[];
}) => {
  const currentUserId = useCurrentUserAttribute('id');

  const [updateOppWorkspaceComment, { isLoading: isUpdating }] = useUpdateOppWorkspaceCommentMutation();
  const [deleteOppWorkspaceComment] = useDeleteOppWorkspaceCommentMutation();

  return (
    <ErrorBoundary action="OppWorkspaceComment" fallback={<CardError title="Opp Workspace Comment" />}>
      <Comment
        key={comment.id}
        comment={comment}
        isAuthor={comment.organizationUserId === currentUserId}
        onRemove={() => {
          deleteOppWorkspaceComment({ id: comment.id });
          deleteToast('Message deleted.');
        }}
        editor={
          <OppWorkspaceCommentForm
            onSubmit={updateOppWorkspaceComment}
            comment={comment}
            oppId={oppId}
            workspaceId={comment.commentableId}
            isLoading={isUpdating}
          />
        }
      >
        <div className="flex flex-wrap gap-2">
          {attachments.map(attachment => (
            <AttachmentEntityTitle
              key={attachment.id}
              {...AttachmentEntityTitleUtils.fromWorkspaceAttachment(attachment)}
            />
          ))}
        </div>
      </Comment>
    </ErrorBoundary>
  );
};
